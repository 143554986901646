import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../components/logo";
import SocialIcon from "../../components/social-icon";
import PropTypes from "prop-types";
import FooterData from "../../data/footer.json";

const Footer = ({ currentLanguage }) => {
    return (
        <footer className="footer-section">
            <div className="footer-top position-relative">
                <img
                    className="footer-shape"
                    src={process.env.PUBLIC_URL + "/images/footer/1.png"}
                    alt="shape"
                />
                <div className="container">
                    <div className="row mb-n7">
                        <div className="col-lg-3 col-sm-6 mb-7">
                            <div className="footer-widget">
                                <Logo
                                    classOption="footer-logo mb-4"
                                    image={`${process.env.PUBLIC_URL}/images/logo/big_logo.png`}
                                />
                                <p>
                                    {
                                        FooterData[0].footer.moto_title[
                                            currentLanguage
                                        ]
                                    }
                                    <br />
                                    <small>
                                        <small>
                                            {
                                                FooterData[0].footer.moto[
                                                    currentLanguage
                                                ]
                                            }
                                        </small>
                                    </small>
                                </p>
                                <ul className="footer-social-links">
                                    <li>
                                        <SocialIcon
                                            classOption="footer-social-link"
                                            path="https://note.com/marvel_engineer/"
                                            icon="icon-note-logo"
                                        />
                                    </li>
                                    <li>
                                        <SocialIcon
                                            classOption="footer-social-link"
                                            path="https://www.wantedly.com/companies/company_2519627/stories"
                                            icon="icon-wantedly-logo"
                                        />
                                    </li>
                                    <li>
                                        <SocialIcon
                                            classOption="footer-social-link"
                                            path="https://www.instagram.com/marvel.engineer_official/"
                                            icon="icofont-instagram"
                                        />
                                    </li>
                                    <li>
                                        <SocialIcon
                                            classOption="footer-social-link"
                                            path="https://www.facebook.com/Marvel.Tokyo.IT"
                                            icon="icofont-facebook"
                                        />
                                    </li>
                                </ul>
                                <a
                                    className="so-badge"
                                    href="https://softwareoutsourcing.com/?utm_id=so_644a362f88129"
                                >
                                    {" "}
                                    <img
                                        className="mt-5"
                                        src="https://softwareoutsourcing.com/wp-content/uploads/Software-Outsourcing-Journal.png"
                                        alt="Software Outsourcing"
                                    />{" "}
                                </a>{" "}
                                <script
                                    defer
                                    src="https://softwareoutsourcing.com/badge.min.js"
                                ></script>
                            </div>
                        </div>
                        <div className="col-lg-2 col-sm-6 mb-7 offset-xl-1">
                            <div className="footer-widget">
                                <h4 className="title">
                                    {
                                        FooterData[0].footer.title_column1[
                                            currentLanguage
                                        ]
                                    }
                                </h4>
                                <ul className="footer-menu">
                                    {FooterData[0].footer.column1.map(
                                        (item) => {
                                            return (
                                                <li key={item.id}>
                                                    <Link
                                                        className="footer-link"
                                                        to={
                                                            process.env
                                                                .PUBLIC_URL +
                                                            item.url
                                                        }
                                                    >
                                                        {
                                                            item.content[
                                                                currentLanguage
                                                            ]
                                                        }
                                                    </Link>
                                                </li>
                                            );
                                        }
                                    )}
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-2 col-sm-6 mb-7">
                            <div className="footer-widget">
                                <h4 className="title">
                                    {
                                        FooterData[0].footer.title_column2[
                                            currentLanguage
                                        ]
                                    }
                                </h4>
                                <ul className="footer-menu">
                                    {FooterData[0].footer.column2.map(
                                        (item) => {
                                            return (
                                                <li key={item.id}>
                                                    <Link
                                                        className="footer-link"
                                                        to={
                                                            process.env
                                                                .PUBLIC_URL +
                                                            item.url
                                                        }
                                                    >
                                                        {
                                                            item.content[
                                                                currentLanguage
                                                            ]
                                                        }
                                                    </Link>
                                                </li>
                                            );
                                        }
                                    )}
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 mb-7">
                            <div className="footer-widget">
                                <h4 className="title">
                                    {
                                        FooterData[0].footer.title_column3[
                                            currentLanguage
                                        ]
                                    }
                                </h4>
                                <p>
                                    {
                                        FooterData[0].footer.excerpt_column3[
                                            currentLanguage
                                        ]
                                    }
                                </p>
                                <ul className="address">
                                    <li>
                                        <a
                                            className="address-link"
                                            href="tel:0368202105"
                                        >
                                            +813-6820-2105
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            className="address-link"
                                            href="mailto:info@marvel.engineer"
                                        >
                                            info@marvel.engineer
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            className="address-link"
                                            href="https://marvel.engineer"
                                        >
                                            https://marvel.engineer
                                        </a>
                                    </li>
                                    <li className="mt-3">
                                        <a
                                            className="address-link"
                                            href="/privacy"
                                        >
                                            プライバシーポリシー
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copy-right-section">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="copyright-info text-center">
                                <p>
                                    Copyright &copy; Since 2024{" "}
                                    <a
                                        href="https://marvel.engineer"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Marvel Inc.
                                    </a>
                                    , All Rights Reserved
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

Footer.propTypes = {
    currentLanguage: PropTypes.string.isRequired,
};

export default Footer;
