import React from "react";
import ScrollToTop from "../components/scroll-to-top";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";
import PropTypes from "prop-types";
import CookieConsentForm from "../components/cookie-consent";
import PageBanner from "../containers/global/page-banner";

const PrivacyPage = ({ currentLanguage, onLanguageSelected }) => {
    return (
        <React.Fragment>
            <Layout>
                <div className="wrapper">
                    <Header
                        onLanguageSelected={onLanguageSelected}
                        currentLanguage={currentLanguage}
                    />
                    <PageBanner
                        title="プライバシーポリシー"
                        image="/images/service/5.png"
                    />
                    <div className="container feature-section position-relative section-py">
                        <div className="main-text">
                            Marvel株式会社（以下「当社」といいます）は、個人情報の保護を重要な責務と考え、お客様および関係者の皆様の個人情報を適切に管理するため、以下のプライバシーポリシーを定め、その取り扱いについての方針をお知らせいたします。
                        </div>
                        <hr className="my-6" />
                        <h4 className="service-title">1. 個人情報の定義</h4>
                        <div className="main-text">
                            本プライバシーポリシーにおいて「個人情報」とは、生存する個人に関する情報であり、氏名、住所、電話番号、メールアドレスなど、特定の個人を識別できる情報を指します。
                        </div>
                        <hr className="my-6" />
                        <h4 className="service-title">2. 個人情報の取得方法</h4>
                        <div className="main-text">
                            当社は、以下のような場合に必要な範囲で個人情報を取得します。
                            <ul>
                                <li>・各種お問い合わせフォームへの入力</li>
                                <li>・サービス利用時の登録情報</li>
                                <li>
                                    ・求人応募における提出書類やオンライン入力情報
                                </li>
                            </ul>
                        </div>
                        <hr className="my-6" />
                        <h4 className="service-title">3. 個人情報の利用目的</h4>
                        <div className="main-text">
                            当社は、取得した個人情報を以下の目的で利用します。
                            <ul>
                                <li>1. お問い合わせや資料請求への対応</li>
                                <li>2. サービスの提供および運営</li>
                                <li>3. 採用選考および応募者へのご連絡</li>
                                <li>4. 法令遵守や契約の履行</li>
                                <li>5. その他、事前に明示した目的</li>
                            </ul>
                        </div>
                        <hr className="my-6" />
                        <h4 className="service-title">
                            4. 個人情報の第三者提供
                        </h4>
                        <div className="main-text">
                            当社は、以下の場合を除き、取得した個人情報を第三者に提供することはありません。
                            <ul>
                                <li>1. ご本人の同意がある場合</li>
                                <li>2. 法令に基づく場合</li>
                                <li>
                                    3.
                                    サービスの提供に必要な業務を委託する場合（例：配送業者や決済代行業者への情報提供）
                                </li>
                            </ul>
                        </div>
                        <hr className="my-6" />
                        <h4 className="service-title">5. 個人情報の安全管理</h4>
                        <div className="main-text">
                            当社は、個人情報を適切に管理するため、以下の対策を講じます。
                            <ul>
                                <li>
                                    ・不正アクセスや情報漏洩を防ぐための技術的・組織的なセキュリティ対策
                                </li>
                                <li>
                                    ・従業員への個人情報保護に関する教育と意識向上
                                </li>
                            </ul>
                        </div>
                        <hr className="my-6" />
                        <h4 className="service-title">
                            6. 個人情報の開示・訂正・削除について
                        </h4>
                        <div className="main-text">
                            ご本人からの請求があった場合、法令に基づき、個人情報の開示、訂正、削除に応じます。手続きの詳細については、下記お問い合わせ窓口までご連絡ください。
                        </div>
                        <hr className="my-6" />
                        <h4 className="service-title">
                            7. クッキー（Cookie）について
                        </h4>
                        <div className="main-text">
                            当社のウェブサイトでは、ユーザーの利便性向上やアクセス状況の分析のためにクッキーを使用する場合があります。クッキーの利用を希望されない場合は、ブラウザ設定で無効にすることができます。
                        </div>
                        <hr className="my-6" />
                        <h4 className="service-title">
                            8. プライバシーポリシーの変更について
                        </h4>
                        <div className="main-text">
                            当社は、法令の変更や運用方針の見直しに伴い、本プライバシーポリシーを適宜変更することがあります。変更後の内容は、当ウェブサイトに掲載した時点で効力を発揮します。
                        </div>
                        <hr className="my-6" />
                        <h4 className="service-title">9. お問い合わせ窓口</h4>
                        <div className="main-text">
                            本プライバシーポリシーや個人情報の取り扱いに関するお問い合わせは、以下の窓口までご連絡ください。
                        </div>
                        <hr className="my-6" />
                        <div className="main-text">
                            Marvel株式会社 〒106-0031 <br />
                            東京都港区西麻布3-21-20 霞町コーポB1 <br />
                            TEL:03-6820-2105 <br />
                            <hr className="" />
                            制定日: 2024年11月1日 <br />
                            Marvel株式会社
                        </div>
                    </div>
                    <Footer currentLanguage={currentLanguage} />
                    <CookieConsentForm currentLanguage={currentLanguage} />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

PrivacyPage.propTypes = {
    currentLanguage: PropTypes.string.isRequired,
    onLanguageSelected: PropTypes.func.isRequired,
};

export default PrivacyPage;
